import {
  all,
  fork,
  call,
  put,
  takeEvery,
  take,
  cancel,
  select,
} from "redux-saga/effects";
import { actions } from "../reducers/chat";
import { rsfDB, firestore } from "../lib/firebase";
import type { Chat } from "../reducers/chat";
import {
  getExhibitionDataAction,
  actions as exhibitionActions,
} from "../reducers/exhibition";

// call은 동기, fork는 비동기 요청
function* syncNumberOfChat(action) {
  const { id, number } = action;
  const task = yield fork(
    rsfDB.syncDocument,
    firestore()
      .collectionGroup("Comment")
      .where("channelId", "==", id)
      .where("isDeleted", "==", false)
      .orderBy("createdAt", "desc")
      .limit(number),
    {
      successActionCreator: (data) => {
        const chats: Array<Chat> = [];
        data.forEach((doc) => {
          chats.unshift({
            id: doc.id,
            name: doc.data().name,
            type: doc.data().type,
            value: doc.data().value,
            target: doc.data()?.targetId,
            createdAt: doc.data().createdAt?.seconds * 1000,
            channelId: doc.data().channelId,
          });
        });
        return {
          type: actions.SYNC_NUMBER_OF_CHAT_SUCCESS,
          data: chats,
        };
      },
      failureActionCreator: (err) => ({
        type: actions.SYNC_NUMBER_OF_CHAT_FAILURE,
        err: err.message,
      }),
    }
  );
  yield take(actions.SYNC_NUMBER_OF_CHAT_DONE);
  yield cancel(task);
}
function* syncAllOfMyChat(action) {
  const { id, title, clientId } = action;

  // const task = yield fork(
  //   rsfDB.syncDocument,
  //   firestore().collection("Channel").doc(id).collection("Comment").orderBy("createdAt", "desc"),
  //   {
  //     successActionCreator: (data) => {
  //       const chats: Array<Chat> = [];
  //       data.forEach((doc) => {
  //         chats.unshift({
  //           id: doc.id,
  //           name: doc.data().name,
  //           type: doc.data().type,
  //           value: doc.data().value,
  //           isDeleted: doc.data().isDeleted,
  //           createdAt: doc.data().createdAt?.seconds * 1000,
  //           channelId: doc.data().channelId,
  //         });
  //       });
  //       return {
  //         type: actions.SYNC_ALL_OF_MY_CHAT_SUCCESS,
  //         data: chats,
  //         id,
  //         title,
  //       };
  //     },
  //     failureActionCreator: (err) => ({
  //       type: actions.SYNC_ALL_OF_MY_CHAT_FAILURE,
  //       err: err.message,
  //     }),
  //   }
  // );
  try {
    const exhibitionData = yield firestore()
      .collection("Exhibition")
      .doc(id)
      .get();
    const isSameClientId = exhibitionData.data().cloudData
      ? exhibitionData.data().cloudData.clientId === clientId
      : false;
    const chats: Array<Chat> = [];

    if (clientId && isSameClientId) {
      const snapshot = yield call(
        rsfDB.getCollection,
        firestore()
          .collectionGroup("Comment")
          .where("channelId", "==", id)
          .where("isDeleted", "==", false)
          .orderBy("createdAt", "desc")
      );
      if (snapshot.size > 0) {
        snapshot.forEach((doc) => {
          chats.unshift({
            id: doc.id,
            name: doc.data().name,
            type: doc.data().type,
            value: doc.data().value,
            target: doc.data()?.targetId,
            isDeleted: doc.data().isDeleted,
            createdAt: doc.data().createdAt?.seconds * 1000,
            channelId: doc.data().channelId,
          });
        });
      }
    } else if (!clientId) {
      const snapshot = yield call(
        rsfDB.getCollection,
        firestore()
          .collectionGroup("Comment")
          .where("channelId", "==", id)
          .where("isDeleted", "==", false)
          .orderBy("createdAt", "desc")
      );
      if (snapshot.size > 0) {
        snapshot.forEach((doc) => {
          chats.unshift({
            id: doc.id,
            name: doc.data().name,
            type: doc.data().type,
            value: doc.data().value,
            target: doc.data()?.targetId,
            isDeleted: doc.data().isDeleted,
            createdAt: doc.data().createdAt?.seconds * 1000,
            channelId: doc.data().channelId,
          });
        });
      }
    }

    yield put({
      type: actions.SYNC_ALL_OF_MY_CHAT_SUCCESS,
      data: chats,
      id,
      title,
    });
  } catch (err) {
    yield put({
      type: actions.SYNC_ALL_OF_MY_CHAT_FAILURE,
      err: err.message,
    });
  }

  // yield take(actions.SYNC_NUMBER_OF_CHAT_DONE);
  // yield cancel(task);
}
function* postChat(action) {
  const {
    id,
    data,
  }: {
    id: string;
    data: { type: "text"; name: string; value: string; password: string };
  } = action;
  // 댓글 입력을 진행할 경우, 유저를 저장한다.
  yield put({
    type: actions.SAVE_USER,
    name: data.name,
    password: data.password,
  });

  try {
    const snapshot = yield call(rsfDB.addDocument, `Channel/${id}/Comment`, {
      ...data,
      createdAt: firestore.FieldValue.serverTimestamp(),
      isDeleted: false,
    });
    // delete 기능을 위해 생성된 id값 추가
    yield call(rsfDB.updateDocument, `Channel/${id}/Comment/${snapshot.id}`, {
      id: snapshot.id,
    });
    yield put({
      type: actions.POST_CHAT_SUCCESS,
    });
  } catch (err) {
    yield put({
      type: actions.POST_CHAT_FAILURE,
      error: err.message,
    });
  }
}
function* deleteChat(action) {
  const {
    channelId,
    commentId,
    targetId,
    password,
    isManager,
  }: {
    channelId: string;
    commentId: string;
    targetId: string;
    password: string;
    isManager?: boolean;
  } = action;
  try {
    if (isManager) {
      if (targetId) {
        yield call(
          rsfDB.updateDocument,
          `Channel/${channelId}/Object/${targetId}/Comment/${commentId}`,
          { isDeleted: true }
        );
      } else {
        yield call(
          rsfDB.updateDocument,
          `Channel/${channelId}/Comment/${commentId}`,
          { isDeleted: true }
        );
      }
    } else {
      const target = yield call(
        rsfDB.getDocument,
        firestore()
          .collection("Channel")
          .doc(channelId)
          .collection("Comment")
          .where("id", "==", commentId)
          .where("password", "==", password)
          .limit(1)
      );

      if (target.size > 0)
        yield call(
          rsfDB.updateDocument,
          `Channel/${channelId}/Comment/${commentId}`,
          { isDeleted: true }
        );
      else
        throw new Error("비밀번호가 틀렸습니다. 비밀번호를 다시 입력해주세요");
    }

    yield put({
      type: actions.DELETE_CHAT_SUCCESS,
    });
  } catch (err) {
    yield put({
      type: actions.DELETE_CHAT_FAILURE,
      error: err.message,
    });
  }
}

function* watchSyncNumberOfChat() {
  yield takeEvery<string>(
    actions.SYNC_NUMBER_OF_CHAT_REQUEST,
    syncNumberOfChat
  );
}
function* watchPostChat() {
  yield takeEvery<string>(actions.POST_CHAT_REQUEST, postChat);
}
function* watchDeleteChat() {
  yield takeEvery<string>(actions.DELETE_CHAT_REQUEST, deleteChat);
}
function* watchSyncAllOfMyChat() {
  yield takeEvery<string>(actions.SYNC_ALL_OF_MY_CHAT_REQUEST, syncAllOfMyChat);
}
// function* watchUpdateGlobalData() {
//   yield takeEvery<string>(actions.UPDATE_GLOBAL_DATA_REQUEST, updateGlobalData);
// }
export default function* chatSaga() {
  yield all([
    fork(watchSyncNumberOfChat),
    fork(watchPostChat),
    fork(watchDeleteChat),
    fork(watchSyncAllOfMyChat),
  ]);
}
