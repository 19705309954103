import { enableAllPlugins } from "immer";
enableAllPlugins();
import { produce } from "immer";
import {
  createStatus,
  createRequestStatus,
  createSuccessStatus,
  createFailureStatus,
  resetStatus,
  ActionStatus,
} from "../utils/reducerUtils";

export type Chat = {
  id: string;
  name: string;
  target?: string;
  type: "text";
  value: string;
  createdAt: number;
  isDeleted?: boolean;
  channelId: string;
  exhibitionTitle?: string;
  exhibitionId?: string;
};

interface GlobalState {
  currentChats: Array<Chat>;
  currentUser: {
    name: string;
    password: string;
  };
  myExhibitionChats: Array<Chat>;
  syncNumberOfChatsStatus: ActionStatus;
  postChatStatus: ActionStatus;
  deleteChatStatus: ActionStatus;
  syncAllOfMyChatStatus: ActionStatus;
}
// 채팅 데이터의 상태 기본값. 데이터를 갖고 오기 전
export const initialState: GlobalState = {
  currentChats: [],
  // 댓글 입력한 사람 임시 저장소. 세션스토리지에도 저장할 예정.
  currentUser: {
    name: "",
    password: "",
  },
  myExhibitionChats: [],
  // status는 loading, done, error을 갖고있음.
  syncNumberOfChatsStatus: createStatus(),
  postChatStatus: createStatus(),
  deleteChatStatus: createStatus(),
  syncAllOfMyChatStatus: createStatus(),
};

// 액션들의 집합.
export const actions = {
  SYNC_NUMBER_OF_CHAT_REQUEST: "SYNC_NUMBER_OF_CHAT_REQUEST",
  SYNC_NUMBER_OF_CHAT_SUCCESS: "SYNC_NUMBER_OF_CHAT_SUCCESS",
  SYNC_NUMBER_OF_CHAT_FAILURE: "SYNC_NUMBER_OF_CHAT_FAILURE",
  SYNC_NUMBER_OF_CHAT_DONE: "SYNC_NUMBER_OF_CHAT_DONE",
  POST_CHAT_REQUEST: "POST_CHAT_REQUEST",
  POST_CHAT_SUCCESS: "POST_CHAT_SUCCESS",
  POST_CHAT_FAILURE: "POST_CHAT_FAILURE",
  DELETE_CHAT_REQUEST: "DELETE_CHAT_REQUEST",
  DELETE_CHAT_SUCCESS: "DELETE_CHAT_SUCCESS",
  DELETE_CHAT_FAILURE: "DELETE_CHAT_FAILURE",
  RESET_DELETE_CHAT_STATUS: "RESET_DELETE_CHAT_STATUS",
  SYNC_ALL_OF_MY_CHAT_REQUEST: "SYNC_ALL_OF_MY_CHAT_REQUEST",
  SYNC_ALL_OF_MY_CHAT_SUCCESS: "SYNC_ALL_OF_MY_CHAT_SUCCESS",
  SYNC_ALL_OF_MY_CHAT_FAILURE: "SYNC_ALL_OF_MY_CHAT_FAILURE",
  SYNC_ALL_OF_MY_CHAT_DONE: "SYNC_ALL_OF_MY_CHAT_DONE",
  // 댓글 입력을 위한 유저 저장 및 로드.
  SAVE_USER: "SAVE_USER",
  LOAD_USER: "LOAD_USER",
};

// 액션 크리에이터. dispatch 내부에서 사용.
export const postChatAction = (
  id: string,
  data: { type: "text"; name: string; value: string; password: string }
) => ({
  type: actions.POST_CHAT_REQUEST,
  id,
  data,
});
export const deleteChatAction = (
  channelId: string,
  commentId: string,
  password: string
) => ({
  type: actions.DELETE_CHAT_REQUEST,
  channelId,
  commentId,
  password,
});
export const resetDeleteChatStatusAction = () => ({
  type: actions.RESET_DELETE_CHAT_STATUS,
});
export const loadUserAction = () => ({
  type: actions.LOAD_USER,
});
export const syncNumberOfChatAction = (id: string, number: number) => ({
  type: actions.SYNC_NUMBER_OF_CHAT_REQUEST,
  id,
  number,
});
export const deleteChatForManagerAction = (
  channelId: string,
  targetId: string,
  commentId: string,
  isManager: boolean = true
) => ({
  type: actions.DELETE_CHAT_REQUEST,
  channelId,
  targetId,
  commentId,
  isManager,
});
export const syncNumberOfChatDoneAction = () => ({
  type: actions.SYNC_NUMBER_OF_CHAT_DONE,
});
export const syncAllOfMyChatAction = (
  id: string,
  title: string,
  clientId: null
) => ({
  type: actions.SYNC_ALL_OF_MY_CHAT_REQUEST,
  id,
  title,
  clientId,
});
export const syncAllOfMyChatDoneAction = () => ({
  type: actions.SYNC_ALL_OF_MY_CHAT_DONE,
});
// 각 액션에 따라 어떻게 데이터를 변경할 것인가를 정한다.
const reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      // user가 소유한 전시회에 달린 댓글 목록
      case actions.SYNC_ALL_OF_MY_CHAT_REQUEST:
        draft.syncAllOfMyChatStatus = createRequestStatus();
        break;
      case actions.SYNC_ALL_OF_MY_CHAT_SUCCESS:
        draft.syncAllOfMyChatStatus = createSuccessStatus();
        draft.myExhibitionChats = [
          ...draft.myExhibitionChats.filter(
            (e) => e.exhibitionId !== action.id
          ),
          ...action.data.map((e) => ({
            ...e,
            exhibitionTitle: action.title,
            exhibitionId: action.id,
          })),
        ];
        break;
      case actions.SYNC_ALL_OF_MY_CHAT_FAILURE:
        draft.syncAllOfMyChatStatus = createFailureStatus(action.error);
        break;
      case actions.SYNC_ALL_OF_MY_CHAT_DONE:
        draft.currentChats = [];
        draft.syncAllOfMyChatStatus = resetStatus();
        break;
      case actions.SYNC_NUMBER_OF_CHAT_REQUEST:
        draft.syncNumberOfChatsStatus = createRequestStatus();
        break;
      case actions.SYNC_NUMBER_OF_CHAT_SUCCESS:
        draft.syncNumberOfChatsStatus = createSuccessStatus();
        draft.currentChats = action.data;
        break;
      case actions.SYNC_NUMBER_OF_CHAT_FAILURE:
        draft.syncNumberOfChatsStatus = createFailureStatus(action.error);
        break;
      case actions.POST_CHAT_REQUEST:
        draft.postChatStatus = createRequestStatus();
        break;
      case actions.POST_CHAT_SUCCESS:
        draft.postChatStatus = createSuccessStatus();
        break;
      case actions.POST_CHAT_FAILURE:
        draft.postChatStatus = createFailureStatus(action.error);
        break;
      case actions.DELETE_CHAT_REQUEST:
        draft.deleteChatStatus = createRequestStatus();
        break;
      case actions.DELETE_CHAT_SUCCESS:
        draft.deleteChatStatus = createSuccessStatus();
        break;
      case actions.DELETE_CHAT_FAILURE:
        draft.deleteChatStatus = createFailureStatus(action.error);
        break;
      case actions.RESET_DELETE_CHAT_STATUS:
        draft.deleteChatStatus = resetStatus();
        break;
      case actions.SYNC_NUMBER_OF_CHAT_DONE:
        draft.currentChats = [];
        draft.syncNumberOfChatsStatus = resetStatus();
        break;
      case actions.LOAD_USER:
        try {
          const user = window.sessionStorage.getItem("currentUser");
          if (user) {
            draft.currentUser = JSON.parse(user);
          }
        } catch {
          console.log("Session Storage is disabled");
        }
        break;
      case actions.SAVE_USER:
        try {
          draft.currentUser.name = action.name;
          draft.currentUser.password = action.password;
          window.sessionStorage.setItem(
            "currentUser",
            JSON.stringify(draft.currentUser)
          );
        } catch {
          console.log("Session Storage is disabled");
        }
        break;
      default:
        break;
    }
  });

export default reducer;
