// 현재 사용하지 않는 리듀서. 관리자 업데이트 할 경우 사용할 예정
import { enableAllPlugins } from "immer";
enableAllPlugins();
import { produce } from "immer";
import {
  createStatus,
  createRequestStatus,
  createSuccessStatus,
  createFailureStatus,
  resetStatus,
  ActionStatus,
} from "../utils/reducerUtils";
import type { Exhibition } from "./exhibition";

export type CollectionObject = {
  // 왜 name & title 둘 다 있을까요?
  id: string;
  name?: string;
  title: string;
  description: string;
  frameThick: number;
  height: number;
  width: number;
  isValied?: boolean;
  createdAt?: number;
  updatedAt?: number;
  cloudData?: {
    publishStatus?: string;
    isHidden?: boolean;
    requestTime?: number;
  };

  originalImage?: {
    path: string;
    url: string;
  };
  thumbnailImage?: {
    path: string;
    url: string;
  };
  compressedImage?: {
    path: string;
    url: string;
  };
  videoUrl?: string;
  audioUrl?: string;
  path?: string;
  iconId?: string;
  iconName?: string;
};

interface CollectionState {
  objectData: CollectionObject;
  allObjects: Array<CollectionObject>;
  myExhibitions: Array<Exhibition>;
  syncedMyExhibitions: Array<Exhibition>;
  getObjectDataStatus: ActionStatus;
  deleteObjectDataStatus: ActionStatus;
  getMyExhibitionsDataStatus: ActionStatus;
  syncMyExhibitionsDataStatus: ActionStatus;
  getAllObjectsDataStatus: ActionStatus;
  syncAllObjectsDataStatus: ActionStatus;
  postObjectDataStatus: ActionStatus;
  updateObjectDataStatus: ActionStatus;
  updateExhibitionDataStatus: ActionStatus;
}
// 글로벌 데이터의 상태 기본값. 데이터를 갖고 오기 전
export const initialState: CollectionState = {
  objectData: {
    id: "",
    name: "",
    title: "",
    description: "",

    originalImage: {
      path: "",
      url: "",
    },
    thumbnailImage: {
      path: "",
      url: "",
    },
    compressedImage: {
      path: "",
      url: "",
    },
    frameThick: 0,
    height: 0,
    width: 0,
    isValied: true,
  },
  allObjects: [],
  myExhibitions: [],
  syncedMyExhibitions: [],
  // status는 loading, done, error을 갖고있음.
  getObjectDataStatus: createStatus(),
  deleteObjectDataStatus: createStatus(),
  getMyExhibitionsDataStatus: createStatus(),
  syncMyExhibitionsDataStatus: createStatus(),
  syncAllObjectsDataStatus: createStatus(),
  getAllObjectsDataStatus: createStatus(),
  postObjectDataStatus: createStatus(),
  updateObjectDataStatus: createStatus(),
  updateExhibitionDataStatus: createStatus(),
};

// 액션들의 집합.
export const actions = {
  GET_OBJECT_DATA_REQUEST: "GET_OBJECT_DATA_REQUEST",
  GET_OBJECT_DATA_SUCCESS: "GET_OBJECT_DATA_SUCCESS",
  GET_OBJECT_DATA_FAILURE: "GET_OBJECT_DATA_FAILURE",
  GET_MY_EXHIBITIONS_DATA_REQUEST: "GET_MY_EXHIBITIONS_DATA_REQUEST",
  GET_MY_EXHIBITIONS_DATA_SUCCESS: "GET_MY_EXHIBITIONS_DATA_SUCCESS",
  GET_MY_EXHIBITIONS_DATA_FAILURE: "GET_MY_EXHIBITIONS_DATA_FAILURE",
  SYNC_MY_EXHIBITIONS_DATA_REQUEST: "SYNC_MY_EXHIBITIONS_DATA_REQUEST",
  SYNC_MY_EXHIBITIONS_DATA_SUCCESS: "SYNC_MY_EXHIBITIONS_DATA_SUCCESS",
  SYNC_MY_EXHIBITIONS_DATA_FAILURE: "SYNC_MY_EXHIBITIONS_DATA_FAILURE",
  SYNC_MY_EXHIBITIONS_DATA_DONE: "SYNC_MY_EXHIBITIONS_DATA_DONE",
  GET_ALL_OBJECT_DATA_REQUEST: "GET_ALL_OBJECT_DATA_REQUEST",
  GET_ALL_OBJECT_DATA_SUCCESS: "GET_ALL_OBJECT_DATA_SUCCESS",
  GET_ALL_OBJECT_DATA_FAILURE: "GET_ALL_OBJECT_DATA_FAILURE",
  SYNC_ALL_OBJECT_DATA_REQUEST: "SYNC_ALL_OBJECT_DATA_REQUEST",
  SYNC_ALL_OBJECT_DATA_SUCCESS: "SYNC_ALL_OBJECT_DATA_SUCCESS",
  SYNC_ALL_OBJECT_DATA_FAILURE: "SYNC_ALL_OBJECT_DATA_FAILURE",
  SYNC_ALL_OBJECT_DATA_DONE: "SYNC_ALL_OBJECT_DATA_DONE",
  POST_OBJECT_DATA_REQUEST: "POST_OBJECT_DATA_REQUEST",
  POST_OBJECT_DATA_SUCCESS: "POST_OBJECT_DATA_SUCCESS",
  POST_OBJECT_DATA_FAILURE: "POST_OBJECT_DATA_FAILURE",
  UPDATE_OBJECT_DATA_REQUEST: "UPDATE_OBJECT_DATA_REQUEST",
  UPDATE_OBJECT_DATA_SUCCESS: "UPDATE_OBJECT_DATA_SUCCESS",
  UPDATE_OBJECT_DATA_FAILURE: "UPDATE_OBJECT_DATA_FAILURE",
  DELETE_OBJECT_DATA_REQUEST: "DELETE_OBJECT_DATA_REQUEST",
  DELETE_OBJECT_DATA_SUCCESS: "DELETE_OBJECT_DATA_SUCCESS",
  DELETE_OBJECT_DATA_FAILURE: "DELETE_OBJECT_DATA_FAILURE",
  RESET_OBJECT_DATA: "RESET_OBJECT_DATA",
  UPDATE_MY_EXHIBITIONS_DATA_REQUEST: "UPDATE_MY_EXHIBITIONS_DATA_REQUEST",
  UPDATE_MY_EXHIBITIONS_DATA_SUCCESS: "UPDATE_MY_EXHIBITIONS_DATA_SUCCESS",
  UPDATE_MY_EXHIBITIONS_DATA_FAILURE: "UPDATE_MY_EXHIBITIONS_DATA_FAILURE",
};
//오브젝트 종류
export type ObjectsType = "Paintings" | "Videos" | "Audios" | "Letters";

// 액션 크리에이터. dispatch 내부에서 사용.
export const getObjectDataAction = (
  objectType: ObjectsType,
  collectionId,
  id
) => ({
  type: actions.GET_OBJECT_DATA_REQUEST,
  objectType,
  collectionId,
  id,
});
export const deleteObjectDataAction = (
  objectType: ObjectsType,
  collectionId,
  id
) => ({
  type: actions.DELETE_OBJECT_DATA_REQUEST,
  objectType,
  collectionId,
  id,
});
export const getAllObjectDataAction = (
  objectType: ObjectsType,
  collectionId: string
) => ({
  type: actions.GET_ALL_OBJECT_DATA_REQUEST,
  objectType,
  collectionId,
});
export const syncAllObjectDataAction = (
  objectType: ObjectsType,
  collectionId: string
) => ({
  type: actions.SYNC_ALL_OBJECT_DATA_REQUEST,
  objectType,
  collectionId,
});
export const syncAllObjectDataDoneAction = () => ({
  type: actions.SYNC_ALL_OBJECT_DATA_DONE,
});
export const getMyExhibitionsDataAction = (collectionId) => ({
  type: actions.GET_MY_EXHIBITIONS_DATA_REQUEST,
  collectionId,
});
export const updateMyExhibitionsDataAction = (collectionId) => ({
  type: actions.UPDATE_MY_EXHIBITIONS_DATA_REQUEST,
  collectionId,
});
export const syncMyExhibitionsDataAction = (
  collectionId,
  cloud = null,
  clientId = null
) => ({
  type: actions.SYNC_MY_EXHIBITIONS_DATA_REQUEST,
  collectionId,
  cloud,
  clientId,
});
export const syncMyExhibitionsDataDoneAction = () => ({
  type: actions.SYNC_ALL_OBJECT_DATA_DONE,
});

export const postObjectDataAction = (
  objectType: ObjectsType,
  collectionId: string,
  data
) => ({
  type: actions.POST_OBJECT_DATA_REQUEST,
  objectType,
  data,
  collectionId,
});
export const updateObjectDataAction = (
  objectType: ObjectsType,
  collectionId,
  id,
  data
) => ({
  type: actions.UPDATE_OBJECT_DATA_REQUEST,
  collectionId,
  objectType,
  id,
  data,
});
export const resetObjectDataAction = () => ({
  type: actions.RESET_OBJECT_DATA,
});

// 각 액션에 따라 어떻게 데이터를 변경할 것인가를 정한다.
const reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case actions.GET_OBJECT_DATA_REQUEST:
        draft.getObjectDataStatus = createRequestStatus();
        break;
      case actions.GET_OBJECT_DATA_SUCCESS:
        draft.getObjectDataStatus = createSuccessStatus();
        draft.objectData = action.data;
        break;
      case actions.GET_OBJECT_DATA_FAILURE:
        draft.getObjectDataStatus = createFailureStatus(action.error);
        break;
      case actions.GET_MY_EXHIBITIONS_DATA_REQUEST:
        draft.getMyExhibitionsDataStatus = createRequestStatus();
        break;
      case actions.GET_MY_EXHIBITIONS_DATA_SUCCESS:
        draft.getMyExhibitionsDataStatus = createSuccessStatus();
        draft.myExhibitions = action.data;
        break;
      case actions.GET_MY_EXHIBITIONS_DATA_FAILURE:
        draft.getMyExhibitionsDataStatus = createFailureStatus(action.error);
        break;
      case actions.SYNC_MY_EXHIBITIONS_DATA_REQUEST:
        draft.syncMyExhibitionsDataStatus = createRequestStatus();
        break;
      case actions.SYNC_MY_EXHIBITIONS_DATA_SUCCESS:
        draft.syncMyExhibitionsDataStatus = createSuccessStatus();
        draft.syncedMyExhibitions = action.data;
        break;
      case actions.SYNC_MY_EXHIBITIONS_DATA_FAILURE:
        draft.syncMyExhibitionsDataStatus = createFailureStatus(action.error);
        break;
      case actions.GET_ALL_OBJECT_DATA_REQUEST:
        draft.getAllObjectsDataStatus = createRequestStatus();
        break;
      case actions.GET_ALL_OBJECT_DATA_SUCCESS:
        draft.getAllObjectsDataStatus = createSuccessStatus();
        draft.allObjects = action.data;
        break;
      case actions.GET_ALL_OBJECT_DATA_FAILURE:
        draft.getAllObjectsDataStatus = createFailureStatus(action.error);
        break;
      case actions.SYNC_ALL_OBJECT_DATA_REQUEST:
        draft.syncAllObjectsDataStatus = createRequestStatus();
        break;
      case actions.SYNC_ALL_OBJECT_DATA_SUCCESS:
        draft.syncAllObjectsDataStatus = createSuccessStatus();
        draft.allObjects = action.data;
        break;
      case actions.SYNC_ALL_OBJECT_DATA_FAILURE:
        draft.syncAllObjectsDataStatus = createFailureStatus(action.error);
        break;
      case actions.SYNC_ALL_OBJECT_DATA_DONE:
        draft.allObjects = [];
      case actions.POST_OBJECT_DATA_REQUEST:
        draft.postObjectDataStatus = createRequestStatus();
        break;
      case actions.POST_OBJECT_DATA_SUCCESS:
        draft.postObjectDataStatus = createSuccessStatus();
        break;
      case actions.POST_OBJECT_DATA_FAILURE:
        draft.postObjectDataStatus = createFailureStatus(action.error);
        break;
      case actions.UPDATE_OBJECT_DATA_REQUEST:
        draft.updateObjectDataStatus = createRequestStatus();
        break;
      case actions.UPDATE_OBJECT_DATA_SUCCESS:
        draft.updateObjectDataStatus = createSuccessStatus();
        break;
      case actions.UPDATE_OBJECT_DATA_FAILURE:
        draft.updateObjectDataStatus = createFailureStatus(action.error);
        break;
      case actions.DELETE_OBJECT_DATA_REQUEST:
        draft.deleteObjectDataStatus = createRequestStatus();
        break;
      case actions.DELETE_OBJECT_DATA_SUCCESS:
        draft.deleteObjectDataStatus = createSuccessStatus();
        break;
      case actions.DELETE_OBJECT_DATA_FAILURE:
        draft.deleteObjectDataStatus = createFailureStatus(action.error);
        break;
      case actions.RESET_OBJECT_DATA:
        draft.objectData = {
          id: "",
          name: "",
          title: "",
          description: "",

          originalImage: {
            path: "",
            url: "",
          },
          thumbnailImage: {
            path: "",
            url: "",
          },
          compressedImage: {
            path: "",
            url: "",
          },
          frameThick: 0,
          height: 0,
          width: 0,
          isValied: true,
        };
        draft.getObjectDataStatus = resetStatus();
        break;
      case actions.UPDATE_MY_EXHIBITIONS_DATA_REQUEST:
        draft.updateExhibitionDataStatus = createRequestStatus();
        break;
      case actions.UPDATE_MY_EXHIBITIONS_DATA_SUCCESS:
        draft.updateExhibitionDataStatus = createSuccessStatus();
        break;
      case actions.UPDATE_MY_EXHIBITIONS_DATA_FAILURE:
        draft.updateExhibitionDataStatus = createFailureStatus(action.error);
        break;
      default:
        break;
    }
  });

export default reducer;
