import { all, fork, put, call, takeEvery, take, cancel, select } from "redux-saga/effects";
import { actions } from "../reducers/exhibition";
import { firestore, rsfDB } from "../lib/firebase";
import { getMyExhibitionsDataAction, actions as collectionActions, ObjectsType } from "../reducers/collection";
import { editExhibition } from "src/routes";

// call은 동기, fork는 비동기 요청
function* syncExhibitionData(action) {
  const { id } = action;
  const task = yield fork(rsfDB.syncDocument, `Exhibition/${id}`, {
    successActionCreator: (data) => ({
      type: actions.SYNC_EXHIBITION_DATA_SUCCESS,
      data: data.data(),
      id: data.id,
    }),
    failureActionCreator: (err) => ({
      type: actions.SYNC_EXHIBITION_DATA_FAILURE,
      err: err.message,
    }),
  });
  yield take(actions.SYNC_EXHIBITION_DATA_DONE);
  yield cancel(task);
}
function* getExhibitionData(action) {
  const { id } = action;
  try {
    const snapshot = yield call(rsfDB.getDocument, `Exhibition/${id}`);
    yield put({
      type: actions.GET_EXHIBITION_DATA_SUCCESS,
      data: snapshot.data(),
      id: snapshot.id,
    });
  } catch (err) {
    yield put({
      type: actions.GET_EXHIBITION_DATA_FAILURE,
      error: err.message,
    });
  }
}
function* updateExhibitionData(action) {
  try {
    const { collectionId, id, data }: { collectionId: string; id: string; data: any } = action;
    yield put(getMyExhibitionsDataAction(collectionId));
    yield take(collectionActions.GET_MY_EXHIBITIONS_DATA_SUCCESS);
    const { myExhibitions } = yield select((state) => state.collection);
    const targetIndex = myExhibitions.findIndex((exhibition) => id === exhibition.id);
    yield call(rsfDB.updateDocument, `Exhibition/${id}`, {
      ...data,
      updatedAt: firestore.FieldValue.serverTimestamp(),
    });
    yield call(
      rsfDB.updateDocument,
      `Collection/${collectionId}`,
      "exhibitions",
      myExhibitions.map((exhibition, i) =>
        i === targetIndex
          ? { ...exhibition, id: exhibition.id, title: data.title }
          : { ...exhibition, id: exhibition.id, title: exhibition.title }
      )
    );

    yield put({
      type: actions.UPDATE_EXHIBITION_DATA_SUCCESS,
    });
  } catch (err) {
    yield put({
      type: actions.UPDATE_EXHIBITION_DATA_FAILURE,
      error: err.message,
    });
  }
}
function* deleteExhibitionData(action) {
  const { collectionId, id }: { collectionId: string; id: string } = action;
  try {
    yield put(getMyExhibitionsDataAction(collectionId));
    yield take(collectionActions.GET_MY_EXHIBITIONS_DATA_SUCCESS);
    const { myExhibitions } = yield select((state) => state.collection);
    const targetIndex = myExhibitions.findIndex((exhibition) => id === exhibition.id);
    if (targetIndex < 0) throw new Error("삭제 권한이 없습니다.");
    yield call(rsfDB.updateDocument, `Exhibition/${id}`, { isDeleted: true });
    yield call(
      rsfDB.updateDocument,
      `Collection/${collectionId}`,
      "exhibitions",
      myExhibitions.map((exhibition, i) => (i === targetIndex ? { ...exhibition, isDeleted: true } : { ...exhibition }))
    );

    yield put({
      type: actions.DELETE_EXHIBITION_DATA_SUCCESS,
    });
  } catch (err) {
    yield put({
      type: actions.DELETE_EXHIBITION_DATA_FAILURE,
      error: err.message,
    });
  }
}
function* postExhibitionData(action) {
  const { id, data, isCloud }: { id: string; data: any; isCloud: boolean } = action;
  try {
    yield put(getMyExhibitionsDataAction(id));
    yield take(collectionActions.GET_MY_EXHIBITIONS_DATA_SUCCESS);
    const { myExhibitions } = yield select((state) => state.collection);
    const checkDuplicatedTitle = (exhibition) => {
      if (isCloud) {
        return data.cloudData.clientId === exhibition.clientId && !exhibition.isDeleted && data.title === exhibition.title;
      } else {
        return data.title === exhibition.title;
      }
    };
    if (myExhibitions.findIndex((exhibition) => checkDuplicatedTitle(exhibition)) > -1) throw new Error("중복된 타이틀 입니다.");
    const snapshot = yield call(rsfDB.addDocument, `Exhibition`, {
      ...data,
      owner: id,
      isDeleted: false,
      previousViews: {},
      views: { todayView: 0 },
      updatedAt: firestore.FieldValue.serverTimestamp(),
      createdAt: firestore.FieldValue.serverTimestamp(),
    });
    if (isCloud) {
      yield call(rsfDB.updateDocument, `Collection/${id}`, "exhibitions", [
        ...myExhibitions.map((exhibition) => ({
          ...exhibition,
          id: exhibition.id,
          title: exhibition.title,
        })),
        {
          id: snapshot.id,
          title: data.title,
          type: "cloud",
          clientId: data.cloudData.clientId,
          isDeleted: false,
        },
      ]);
    } else {
      yield call(rsfDB.updateDocument, `Collection/${id}`, "exhibitions", [
        ...myExhibitions.map((exhibition) => ({
          ...exhibition,
          id: exhibition.id,
          title: exhibition.title,
        })),
        {
          id: snapshot.id,
          title: data.title,
          type: "platform",
          isDeleted: false,
        },
      ]);
    }
    yield put({
      type: actions.POST_EXHIBITION_DATA_SUCCESS,
      id: snapshot.id,
    });
  } catch (err) {
    yield put({
      type: actions.POST_EXHIBITION_DATA_FAILURE,
      error: err.message,
    });
  }
}
function* postObjectToExhibitionData(action) {
  const {
    objectType,
    exhibitionId,
    objectId,
    data,
  }: {
    objectType: ObjectsType;
    exhibitionId: string;
    objectId: string;
    data: any;
  } = action;
  try {
    yield call(rsfDB.setDocument, `Exhibition/${exhibitionId}/${objectType}/${objectId}`, data, { merge: true });
    yield put({
      type: actions.POST_OBJECT_TO_EXHIBITION_DATA_SUCCESS,
    });
  } catch (err) {
    yield put({
      type: actions.POST_OBJECT_TO_EXHIBITION_DATA_FAILURE,
      error: err.message,
    });
  }
}
function* syncExhibitionObjectData(action) {
  const { objectType, id } = action;
  const task = yield fork(
    rsfDB.syncCollection,
    firestore().collection("Exhibition").doc(id).collection(objectType).where("isDeleted", "==", false),
    {
      successActionCreator: (snapshot) => {
        const data = [];
        snapshot.forEach((doc) => {
          data.push({
            ...doc.data(),
            id: doc.id,
            createdAt: doc.data()?.createdAt?.seconds * 1000,
            updatedAt: doc.data()?.updatedAt?.seconds * 1000,
          });
        });
        return {
          type: actions.SYNC_EXHIBITION_OBJECT_DATA_SUCCESS,
          data,
        };
      },
      failureActionCreator: (err) => ({
        type: actions.SYNC_EXHIBITION_OBJECT_DATA_FAILURE,
        err: err.message,
      }),
    }
  );
  yield take(actions.SYNC_EXHIBITION_OBJECT_DATA_DONE);
  yield cancel(task);
}

function* postExhibitionObjectData(action) {
  const { objectType, id, data } = action;
  try {
    // yield call(rsfDB.addDocument, `Collection/${collectionId}/${objectType}`, {
    //   ...data,
    //   isDeleted: false,
    //   createdAt: firestore.FieldValue.serverTimestamp(),
    //   updatedAt: firestore.FieldValue.serverTimestamp(),
    // });
    yield call(rsfDB.addDocument, `Exhibition/${id}/${objectType}`, {
      ...data,
      isDeleted: false,
      createdAt: firestore.FieldValue.serverTimestamp(),
      updatedAt: firestore.FieldValue.serverTimestamp(),
    });
    yield put({
      type: actions.POST_EXHIBITION_OBJECT_DATA_SUCCESS,
    });
  } catch (err) {
    yield put({
      type: actions.POST_EXHIBITION_OBJECT_DATA_FAILURE,
      error: err.message,
    });
  }
}
function* updateExhibitionObjectData(action) {
  const { objectType, exhibitionId, id, data } = action;
  try {
    yield call(rsfDB.updateDocument, `Exhibition/${exhibitionId}/${objectType}/${id}`, {
      ...data,
      updatedAt: firestore.FieldValue.serverTimestamp(),
    });
    yield put({
      type: actions.UPDATE_EXHIBITION_OBJECT_DATA_SUCCESS,
    });
  } catch (err) {
    yield put({
      type: actions.UPDATE_EXHIBITION_OBJECT_DATA_FAILURE,
      error: err.message,
    });
  }
}
function* deleteExhibitionObjectData(action) {
  const { objectType, exhibitionId, id } = action;
  try {
    yield call(rsfDB.updateDocument, `Exhibition/${exhibitionId}/${objectType}/${id}`, { isDeleted: true });
    yield put({
      type: actions.DELETE_EXHIBITION_OBJECT_DATA_SUCCESS,
    });
  } catch (err) {
    yield put({
      type: actions.DELETE_EXHIBITION_OBJECT_DATA_FAILURE,
      error: err.message,
    });
  }
}
function* watchSyncExhibitionData() {
  yield takeEvery<string>(actions.SYNC_EXHIBITION_DATA_REQUEST, syncExhibitionData);
}
function* watchGetExhibitionData() {
  yield takeEvery<string>(actions.GET_EXHIBITION_DATA_REQUEST, getExhibitionData);
}

function* watchUpdateExhibitionData() {
  yield takeEvery<string>(actions.UPDATE_EXHIBITION_DATA_REQUEST, updateExhibitionData);
}
function* watchPostExhibitionData() {
  yield takeEvery<string>(actions.POST_EXHIBITION_DATA_REQUEST, postExhibitionData);
}
function* watchDeleteExhibitionData() {
  yield takeEvery<string>(actions.DELETE_EXHIBITION_DATA_REQUEST, deleteExhibitionData);
}
function* watchPostObjectToExhibitionData() {
  yield takeEvery<string>(actions.POST_OBJECT_TO_EXHIBITION_DATA_REQUEST, postObjectToExhibitionData);
}
function* watchSyncExhibitionObjectData() {
  yield takeEvery<string>(actions.SYNC_EXHIBITION_OBJECT_DATA_REQUEST, syncExhibitionObjectData);
}
function* watchPostExhibitionObjectData() {
  yield takeEvery<string>(actions.POST_EXHIBITION_OBJECT_DATA_REQUEST, postExhibitionObjectData);
}
function* watchUpdateExhibitionObjectData() {
  yield takeEvery<string>(actions.UPDATE_EXHIBITION_OBJECT_DATA_REQUEST, updateExhibitionObjectData);
}
function* watchDeleteExhibitionObjectData() {
  yield takeEvery<string>(actions.DELETE_EXHIBITION_OBJECT_DATA_REQUEST, deleteExhibitionObjectData);
}
export default function* globalSaga() {
  yield all([
    fork(watchSyncExhibitionData),
    fork(watchGetExhibitionData),
    fork(watchUpdateExhibitionData),
    fork(watchPostObjectToExhibitionData),
    fork(watchPostExhibitionData),
    fork(watchDeleteExhibitionData),
    fork(watchSyncExhibitionObjectData),
    fork(watchPostExhibitionObjectData),
    fork(watchUpdateExhibitionObjectData),
    fork(watchDeleteExhibitionObjectData),
  ]);
}
